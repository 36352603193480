.App {
    text-align: start;
  }
  .image-list {
    display: flex;
    overflow-x: auto;
  }
  .image-list img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .preview {
    display: none;
    margin-top: 10px;
  }
  
  .preview img {
    max-width: 100%;
    max-height: 400px;
  }