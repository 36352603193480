body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.changebg {
  background-color: rgb(241 241 241);
  padding: 10px;
  border-radius: 5px;
  margin: 15px 0 15px;
}
.topComment {
  margin-top: 15px;
  margin-bottom: 0 !important;
}

.ql-container {
  min-height: 40vh;
}
.react-share__ShareButton {
  margin-left: 5px !important;
}
.highlight {
  background-color: yellow;
  font-weight: bold;
}

.filter-button {
  padding: 10px 20px;
  background-color: #f1f1f1;
  color: #333;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

.filter-button:hover {
  background-color: #ddd;
}

.filter-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}
