@font-face {
  font-family: "Mulish";
  src: url("../fonts/Mulish-Regular.woff2") format("woff2"),
    url("../fonts/Mulish-Regular.woff") format("woff"),
    url("../fonts/Mulish-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter-Regular.woff") format("woff"),
    url("../fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff"),
    url("../fonts/SFProDisplay-Regular.ttf") format("truetype");
}
a {
  text-decoration: none !important;
}

.credit-score {
  width: auto;
  height: 15px;

  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  top: 9px;
  letter-spacing: 0.2px;
  color: #343434;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.credit-score-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 16px;

  position: relative;
  left: 12px;
  width: auto;
  height: 23px;
  // left: 191px;
  // top: 80px;

  background-color: #ffcfd4;
  border-radius: 28px;

  /* Inside auto layout */

  flex: none;
  order: 6;
  flex-grow: 0;
  z-index: 6;
}

.loginMain {
  @media screen and (min-height: 700px) {
    height: 100vh;
  }
  .logo {
    background: white;
    border-radius: 30px;
    padding: 5px 20px;
  }
  .loginBox {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
    h3 {
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.4px;
      color: #252733;
    }
    p {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #0000ff;
      cursor: pointer;
    }
  }
}

.cancelBtn {
  border: 1px solid #747474;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #747474;
  width: 81px;
  height: 40px;
  background-color: transparent;
  &:hover {
    background-color: #747474 !important;
    color: white !important;
    border: 1px solid #747474 !important;
  }
}
.cancelBtn.filled {
  background-color: #747474 !important;
  color: white !important;
  border: 1px solid #747474 !important;
  &:hover {
    background-color: transparent !important;
    color: #747474 !important;
    border: 1px solid #747474 !important;
  }
}
.registerBtn {
  border: 1px solid #747474;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  width: 81px;
  height: 40px;
  background-color: #343434;
  color: white;
  border: 1px solid #343434;
  &:hover {
    background-color: transparent !important;
    color: #343434 !important;
    border: 1px solid #343434 !important;
  }
}
.loginBtn {
  border: 1px solid #cc2939;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.2px;
  color: white;
  width: 81px;
  height: 40px;
  background-color: #cc2939;
  &:hover {
    background-color: transparent !important;
    color: #cc2939 !important;
    border: 1px solid #cc2939 !important;
  }
}
.w-100px {
  width: 100px !important;
}
.closeBtn {
  border: 1px solid #343434;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.2px;
  color: white;
  width: 81px;
  height: 40px;
  background-color: #343434;
  &:hover {
    background-color: transparent !important;
    color: #343434 !important;
    border: 1px solid #343434 !important;
  }
}
.form-select {
  border: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474;
  position: absolute !important;
  padding-left: 52px !important;
  background-color: transparent !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  &:focus {
    box-shadow: none;
  }
}
label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #747474;
}
input.form-control {
  border: 1px solid #747474 !important;
  border-radius: 4px !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474 !important;
  background: transparent;
  &::placeholder {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #747474;
  }
  &:focus {
    box-shadow: none !important;
    background: transparent;
  }
}
.form-control:disabled {
  background: transparent;
}
.input-group {
  border: 1px solid #747474 !important;
  border-radius: 4px !important;
  padding: 5px 0px;
  height: 45px;
  .input-group-text {
    background: transparent;
    border: none;
    img {
      width: 20px;
      margin-top: -2px;
    }
  }
  input {
    border: none !important;
    padding-left: 0px;
  }
}
.fa-bars {
  position: fixed;
  z-index: 22222;
  left: 10px;
  top: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in;
}
.fa-bars.openSidebar {
  left: 280px;
}
.sideBar {
  position: fixed;
  background: #f1f1f1;
  width: 270px;
  height: 100%;
  padding: 20px;
  margin-left: 0;
  transition: all 0.5s ease-in;

  h1 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #343434;
    margin-bottom: 2px;
  }
  h5 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #cc2939;
    margin-bottom: 0px;
  }
  .dpImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  ul {
    padding-left: 0px;
    i {
      width: 20px;
      margin-right: 10px;
      margin-top: -3px;
      color: #343434;
    }
    li {
      list-style: none;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #343434;
      margin-bottom: 0px;
      text-decoration: none;
      cursor: pointer;
      ul {
        padding-left: 30px;
        margin-top: 10px;
        a {
          text-decoration: none;
        }
        li {
          margin-bottom: 0px;
          padding-left: 10px;
          padding: 13px;
        }
        li.active {
          background: #ffcfd4;
          border-radius: 10px;
          color: black;
          width: 150px;
        }
      }
    }
    li.active {
      color: white;
      background: #cc2939;
      border-radius: 10px;
      padding: 13px;
      display: inline-block;
      i {
        color: white;
      }
    }
    li {
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px;
        i.fa-angle-down {
          visibility: visible;
        }
        div {
          i.fa-angle-down {
            visibility: hidden !important;
          }
        }
      }
      span.active {
        i.fa-angle-down {
          visibility: hidden;
        }
        div {
          color: white;
          background: #cc2939;
          border-radius: 10px;
          padding: 13px;
          display: flex;
          align-items: baseline;
          i {
            color: white;
            visibility: visible !important;
          }
          .fa-angle-down {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .signOut {
    list-style: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #343434;
    margin-bottom: 0px;
    // position: absolute;
    bottom: 30px;
    cursor: pointer;
    img {
      width: 20px;
      margin-right: 10px;
      margin-top: -3px;
    }
  }
}
.openSidebar {
  margin-left: 0px;
  z-index: 99;
}
.closeSidebar {
  margin-left: -270px;
  z-index: 99;
}
.sidePage {
  margin-left: 270px;
  .px-sm-5.py-4 {
    padding-top: 80px !important;
    // @media screen and (max-width: 575px) {
    //   padding-top: 100px !important;
    // }
  }
}
.sidePage.openSidebar {
  margin-left: 270px;
  transition: all 0.5s ease-in;
  @media screen and (max-width: 992px) {
    margin-left: 0px;
  }
}
.sidePage.closeSidebar {
  margin-left: 0px;
  transition: all 0.5s ease-in;
}
.breadCrumb {
  h3 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
  }
}
.cardSection {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 20px;
  h1 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.4px;
    color: #252733;
  }
  .profileImg {
    width: 110px;
    height: 110px;
    border-radius: 50%;
  }
}
.forgotPassword {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0000ff;
  cursor: pointer;
}
.identifier {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #252733;
}
.signupMain {
  background-image: url(../../../public/imgs/signup-bg.png);
  background-size: cover;
  min-height: 100vh;
  .logo {
    background: white;
    border-radius: 30px;
    padding: 5px 20px;
  }
  h3 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 54px;

    color: #ffffff;
    text-align: center;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;

    color: #ffffff;
    text-align: center;
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 30%;
    height: 30%;
    background-color: #f8f8f8ad;

    // .loader{
    //   left: 40%;
    //   top: 30%;
    //   z-index: 1000;
    //   position: absolute;
    // }
  }
  .SignupBox {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid #a6444d;
    border-radius: 20px;
    padding: 40px;
    label {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;

      /* identical to box height */

      color: #ffffff !important;
    }
    input {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      background-color: transparent;
      /* identical to box height */

      color: white !important;
      &::placeholder {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        /* identical to box height */

        color: rgba(255, 255, 255, 0.5);
      }
    }
    .form-check-label {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      padding-left: 10px !important;
      /* identical to box height */
      text-transform: capitalize;
      color: #ffffff;
    }
    .form-check-input {
      border: 1px solid #747474 !important;
      height: 20px;
      width: 20px;

      &:focus {
        box-shadow: none;
      }
    }
    .forgotpassword {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: rgba(255, 255, 255, 0.7);
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}
.rdt_Table {
  img {
    cursor: pointer;
  }
}
.rdt_TableCol {
  div {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;

    /* identical to box height */
    letter-spacing: 0.2px;

    /* grayscale / gray */
    color: #9fa2b4;
  }
}
.rdt_TableCell {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  div {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    /* identical to box height, or 143% */
    letter-spacing: 0.2px;
    overflow: inherit !important;
    /* grayscale / black */
    color: #252733;
  }
}
.status {
  border-radius: 60px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 3px 10px;
  /* identical to box height */
  letter-spacing: 0.2px;
  margin-top: 10px;
}
.status.active {
  background: #03f690;
  color: #000000;
}
.status.active1 {
  background: gray;
  color: #ffffff;
}
.status.draft {
  background: #fede38;
  color: #000000;
}
.status.draft1 {
  background: #fede38;
  color: #ffffff;
}
.status.revoked {
  background: #f6034c;
  color: white;
}
.status.published {
  background: green;
  color: white;
}
.status.score {
  background: blue;
  color: white;
}
.status.hide {
  background: #343434;
  color: white;
}
.rdt_TableRow {
  border-bottom: none !important;
  justify-content: space-between !important;
}

.rdt_Pagination {
  margin-top: 20px;
  border: none !important;
}
.tblCheckBox {
  text-align: right;
  input {
    border: 2px solid #747474;
    border-radius: 0px !important;
  }
}
.table-bordered {
  th {
    border: 1px solid #747474;
  }
  td {
    border: 1px solid #747474 !important;
  }
}
.w-170 {
  width: 170px;
}
.rdw-editor-wrapper {
  border: 1px solid #747474;
  border-radius: 5px;
  padding: 5px;
  .rdw-editor-main {
    padding: 20px 10px;
    min-height: 300px;
  }
  .rdw-block-wrapper,
  .rdw-fontsize-wrapper,
  .rdw-fontfamily-wrapper {
    display: none !important;
  }
}
.ReactTags__tags.react-tags-wrapper {
  border: 1px solid #747474;
  border-radius: 6px;
  padding: 10px;
  .ReactTags__tag {
    background: #cc2939;
    border-radius: 6px;
    padding: 5px;
    padding-left: 8px;
    margin-right: 5px;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    /* identical to box height */
    letter-spacing: 0.2px;

    /* white */
    color: #ffffff;
    button {
      background: transparent;
      border: none;
      color: white;
    }
  }
  input {
    border: none;
    margin-top: 5px;
    &:focus-visible {
      outline: none;
    }
  }
}
.coverImg {
  h5 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    /* identical to box height */
    letter-spacing: 0.2px;

    color: #000000;
  }
}
.avatar-edit {
  position: relative;
  width: 294px;
  height: 184px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  img {
    width: 100%;
  }
  #imageUpload {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    cursor: pointer;
  }
}
#imagePreview {
  img {
    border-radius: 6px;
    width: 294px;
    height: 184px;
    object-fit: cover;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
}
#imagePreview1 {
  img {
    border-radius: 6px;
    width: 212px;
    height: 116px;
    object-fit: cover;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
}
.imagePreviewModal {
  img {
    width: 100%;
    border-radius: 5px;
    height: 300px;
    object-fit: cover;
  }
}
.image-placeholder {
  display: flex;
  gap: 20px;
  input {
    background: #f4f4f4;
    border: none;
    margin-top: 5px;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.avatar-preview {
  position: relative;
  .delImg {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .form-check {
    margin-left: 10px;
    margin-top: 10px;
  }
  .form-check-input {
    border-color: #cc2939;
    border-radius: 50%;
    margin-top: 6px;
    &:focus {
      box-shadow: none;
    }
  }
  .form-check-input:checked {
    background-color: #cc2939;
    border-color: #cc2939;
  }
  .form-check-label {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    /* identical to box height */
    letter-spacing: 0.2px;

    color: #000000;
  }
}
.modal-content {
  .closeIcon {
    cursor: pointer;
  }
}
.modal-body {
  h1 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;

    /* identical to box height */
    letter-spacing: 0.4px;

    /* grayscale / black */
    color: #252733;
  }
}
textarea {
  border: 1px solid #747474 !important;
  resize: none;
  &:focus {
    box-shadow: none !important;
    outline: none;
  }
}
.reference {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  /* identical to box height */
  letter-spacing: 0.2px;

  color: #000000;
}
.modal-dialog {
  max-width: 700px;
}
.sideMenuheight {
  height: 75vh;
  overflow-y: scroll;
  scrollbar-width: none;
}
.sideMenuheight::-webkit-scrollbar {
  display: none !important;
}
:root {
  scrollbar-color: grey;
  scrollbar-width: 5px;
}
.uploadImg {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  /* grey */
  color: #747474;
}
.uploadImgMain {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin: 0 auto;
}
.uploadImgIcon {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0);
}
.borderDashed {
  border: 1px dashed #747474;
  border-radius: 5px;
  padding: 20px;
  h1 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    /* identical to box height */
    letter-spacing: 0.4px;

    /* grayscale / black */
    color: #252733;
  }
  h2 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    /* identical to box height */
    letter-spacing: 0.4px;

    /* grayscale / black */
    color: #252733;
  }
  .uploadBtnDesign {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    input {
      opacity: 0;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    button {
      cursor: pointer;
      z-index: 0;
      position: relative;
    }
  }
}
.ugradePlanEditor {
  .rdw-editor-wrapper {
    border: 1px solid #d9d9d9;
  }
}
.recommended {
  .form-check-input {
    background: #d9d9d9;
    border-radius: 2px;
    border: none;
    margin-top: 8px;
    &:focus {
      box-shadow: none;
    }
  }
  .form-check-input:checked[type="radio"] {
    background-color: #cc2939;
  }
}
.pageManagement {
  a {
    text-decoration: none;
  }
  .block {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px #0000000a;
    transition: all 0.3s ease;
    &:hover {
      box-shadow: 11px 8px 6px 0px #0000000a;
    }
    h1 {
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      text-decoration: none;
      /* identical to box height */
      letter-spacing: 0.4px;

      /* grey */
      color: #747474;
    }
  }
}
.accordion {
  .accordion-item {
    border: none;
    .accordion-button {
      justify-content: center;
      border: none;
      padding: 0px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      /* identical to box height, or 150% */
      letter-spacing: -0.2px;

      color: #0000ff;
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .accordion-button:not(.collapsed) {
      background: transparent;
      box-shadow: none;
    }
  }
  .accordion-body {
    padding: 0px;
  }
}
.adressCheckbox {
  .form-check-input[type="checkbox"] {
    border: 1.5px solid #0832de;
    border-radius: 0px !important;
    margin-top: 7px;
  }
}
.articleDetail {
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
    /* identical to box height, or 150% */
    border-bottom: 0.5px solid #747474;
    padding: 10px;
    color: #747474;
  }
  .tag {
    background: #cc2939;
    border-radius: 6px;
    padding: 5px;
    padding-left: 5px;
    padding-left: 8px;
    margin-right: 5px;
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #ffffff;
    width: auto;
  }
  .accordion {
    .accordion-item {
      margin-bottom: 20px;
      .accordion-button {
        justify-content: start;
        font-family: "Mulish", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 17.2082px;
        line-height: 22px;
        letter-spacing: 0.382405px;
        color: #252733;
        border: 0.956012px solid #d9d9d9;
        padding: 15px 17px;
        border-radius: 5px;
        &::after {
          display: block;
          background-size: 12px !important;
          width: 12px !important;
          height: 12px !important;
        }
      }
      .accordion-button:not(.collapsed)::after {
      }
      .apexcharts-canvas {
        background: #d3d3d33b;
      }
      .accordion-body {
        padding: 0px;
        border: 0.956012px solid #d9d9d9;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      .ratingReviews {
        .d-flex {
          align-items: center;
        }
        .input-group {
          width: 250px;
        }
        p {
          padding: 0px;
          border: none;
          span {
            font-size: 30px;
            color: #343434;
          }
          div {
            text-align: center;
          }
        }
      }
    }
  }
}
.rating {
  display: flex;
  gap: 5px;
  .checked {
    color: #fede38;
  }
}
.reviewrsList {
  border-top: 0.956012px solid #d9d9d9;
  padding: 10px;
  img {
    width: 65px;
    height: 65px;
  }
  .data {
    max-width: 75%;
  }
  h3 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15.2962px;
    line-height: 23px;

    color: #000000;
  }
  p {
    padding: 0px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15.2962px;
    line-height: 23px;

    border: none;
    color: #000000;
    margin-top: 5px;
  }
}
.approvalForm {
  .subTitle {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    /* identical to box height */
    letter-spacing: 0.2px;

    color: #000000;
  }
  input.form-control {
    height: 48px;
  }
  select {
    border: 1px solid #747474;
    border-radius: 6px;
    height: 48px;
  }
  .loginBtn {
    height: 48px;
    margin-top: 29px;
  }
}

video {
  border-radius: 10px;
}
.thumbnail {
  h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #343434;
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #747474;
    margin-bottom: 0px;
  }
  #imagePreview {
    img {
      width: 116px;
      height: 60px;
      border-radius: 5px;
    }
  }
  .avatar-edit {
    width: 116px;
    height: 60px;
  }
}
.score {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;

  /* identical to box height */
  letter-spacing: 0.4px;

  /* grayscale / black */
  color: #252733;
}
.questions {
  h1 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;

    /* identical to box height */
    letter-spacing: 0.4px;

    /* grayscale / black */
    color: #252733;
  }
  .answerBox {
    textarea {
      background: #ffffff;
      border: 1px solid #000000 !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      &:focus {
        border: 1px solid #000000 !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
}
.articleReply {
  img {
    width: 50px;
  }
  h5 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    /* identical to box height, or 143% */
    letter-spacing: 0.2px;
    margin-bottom: 0px;
    /* grayscale / black */
    color: #252733;
  }
  h6 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;

    /* identical to box height, or 143% */
    letter-spacing: 0.2px;
    margin-bottom: 0px;
    /* grayscale / black */
    color: #252733;
  }
  .rating {
    display: flex;
    gap: 1px;
    .fa {
      font-size: 10px;
      line-height: 19px;
    }
    .fa.checked {
      color: #cc2939;
    }
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
    /* or 171% */

    /* grey */
    color: #747474;
  }
  .replyDiv {
    background: #f1f1f1;
    border-radius: 4px;
    padding: 5px 10px;
    width: 96%;
    margin-left: auto;
    img {
      width: 20px;
      height: 20px;
    }
    input {
      border: none !important;
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;

      /* identical to box height */
      letter-spacing: 0.4px;

      /* grey */
      color: #747474;
      &::placeholder {
        font-family: "Mulish", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;

        /* identical to box height */
        letter-spacing: 0.4px;

        /* grey */
        color: #747474;
      }
    }
    .loginBtn {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      width: 47px;
      height: 20px;
      border-radius: 30px;
      text-align: center;
      letter-spacing: 0.15px;
      padding: 2px;
      /* white */
      color: #ffffff;
    }
  }
}
@media screen and (max-width: 575px) {
  .cardSection > .d-flex.justify-content-between {
    flex-direction: column;
  }
}
.slider {
  h1 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.4px;
    margin-bottom: 0px;
    /* grayscale / black */
    color: #252733;
  }
  p {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    /* identical to box height */
    letter-spacing: 0.4px;

    /* grayscale / black */
    color: gray;

    span {
      color: black;
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    span {
      background-color: #cc2939;
      border-radius: 50%;
      background-size: 48%;
      opacity: 1;
    }
  }
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.carousel-indicators {
  margin-bottom: -30px !important;
  button {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
    opacity: 1 !important;
    background-color: lightgray !important;
  }
  button.active {
    background-color: #cc2939 !important;
  }
}
.productDetailBtn {
  margin-top: 31px;
  height: 46px;
}
.editIcon {
  width: 32px;
  height: 32px;
}
.breadCrumb {
  margin-bottom: 0px;
  .breadcrumb-item {
    a {
      text-decoration: none;
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #252733;
    }
  }
  .breadcrumb-item.active {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    /* identical to box height */
    letter-spacing: 0.4px;

    /* grey */
    color: #747474;
  }
}
.forgetPasswordBtn {
  width: 147px;
  height: 30px;
}
.headerMain {
  position: fixed;
  left: 270px;
  right: 0;
  background: white;
  transition: all ease-in 0.5s;
  top: 0;
  z-index: 9;
  @media screen and (max-width: 992px) {
    left: 0px !important;
  }
}
.sidePage.closeSidebar {
  .headerMain {
    left: 0px;
    @media screen and (max-width: 992px) {
      left: 0px !important;
    }
  }
}

.header {
  .logo {
    width: 160px;
  }
  .btn-default {
    border: 0;
    padding: 8px;
    .btn-primary {
      color: #000;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }
    .dropdown-menu {
      left: -91px !important;
      text-align: center;
      width: 180px;
      padding: 15px;
    }
  }
  a {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-decoration: none;
    /* identical to box height */

    padding-right: 5px !important;
    padding-left: 5px !important;
    color: #000000;
  }
  .notifBg {
    background: rgba(208, 210, 218, 0.1);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 18px;
    }
  }
  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
    /* identical to box height, or 150% */

    color: #ffffff;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
    /* identical to box height, or 150% */

    /* Greys/Blue Grey/600 */
    color: #a1a5b6;
  }
  .profileImg {
    width: 40px;
    height: 40px;
  }
}
.navbar-toggler {
  border: none;
  position: absolute;
  right: 90px;
  top: 10px;
  &:focus {
    box-shadow: none;
  }
  i {
    color: black;
    position: static;
  }
}
.withoutLogin {
  .navbar-toggler {
    position: absolute;
    right: 50px;
  }
}
.w-md-100 {
  @media screen and (max-width: 1190px) {
    width: 100%;
  }
}
.px-50px {
  padding: 0 50px;
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
}
.container-fluid {
  position: relative;
}
.dropdown {
  @media screen and (max-width: 992px) {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .dropdown-toggle {
    background: transparent;
    border: none;
    padding: 0px;
    &:hover {
      background: transparent;
    }
    &::after {
      display: none;
    }
    .profileImg {
      border-radius: 100px;
    }
  }
  .dropdown-menu {
    left: -91px !important;
    text-align: center;
    width: 215px;
    padding: 30px;
    .profileImgInner {
      border-radius: 100px;
      display: block;
      margin: 0 auto 13px auto;
      width: 60px;
      height: 60px;
    }
    .name {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
    }
    .contributor {
      border: 1px solid #cc2939;
      border-radius: 60px;
      padding: 8px 12px;
      display: inline-block;
      margin: 5px 0 20px 0;
      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #000000;
      }
    }
    .dropdown-item {
      background: #fff;
      border-radius: 10px;
      color: #343434;
      margin-top: 5px;
      padding: 10px;
      img {
        margin-right: 5px;
      }
      &:hover {
        background: #0832de;
        border-radius: 10px;
        color: #fff;
        margin-top: 5px;
        img {
          filter: invert(1);
        }
      }
    }
    .active {
      background: #0832de;
      border-radius: 10px;
      color: #fff;
      img {
        filter: invert(1);
      }
    }
  }
}
.bannerSection {
  background-image: url(../../../public/imgs/banner.png);
  background-size: cover;
  padding: 5% 0px;
  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 54px;

    /* identical to box height */

    text-align: center;
    color: #ffffff;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize;
  }
  button {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    border: 1px solid #cc2939;
    margin-top: 20px;

    background: #cc2939;
    border-radius: 8px;
    color: #ffffff;
    min-width: 300px;
    height: 45px;
  }
  .viewAllBlog {
    background: #ffffff;
    border-radius: 8px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    border: 1px solid white;
    /* identical to box height */
    min-width: 200px;
    color: #000000;
  }
}
.tourMain {
  padding: 20px;
  min-height: 400px;
  position: relative;
  border-radius: 10px;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    z-index: 0;
    cursor: pointer;
  }
  button {
    position: relative;
    width: auto;
    min-width: auto;
    padding: 0px 10px;
    font-size: 14px;
    height: 40px;
    margin-top: 0px;
  }
  .bottomText {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    h3 {
      position: relative;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 0px;
      /* identical to box height */
      text-align: center;

      color: #ffffff;
    }
    h6 {
      position: relative;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      /* identical to box height */
      text-align: center;
      text-transform: uppercase;

      color: #ffffff;
    }
  }
}

.tourMainCard {
  padding: 20px;
  min-height: 250px;
  position: relative;
  border-radius: 10px;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    z-index: 0;
  }
  button {
    position: relative;
    width: auto;
    min-width: auto;
    padding: 0px 10px;
    font-size: 14px;
    height: 40px;
    margin-top: 0px;
  }
  .bottomText {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    h3 {
      position: relative;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 0px;
      /* identical to box height */
      text-align: center;

      color: #ffffff;
    }
    h6 {
      position: relative;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      /* identical to box height */
      text-align: center;
      text-transform: uppercase;

      color: #ffffff;
    }
  }
}
.aboutSection {
  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 72px;
    margin-bottom: 20px;
    /* identical to box height */

    color: #000000;
    @media screen and (max-width: 767px) {
      font-size: 30px;
      margin-top: 30px;
      margin-bottom: 0px;
    }
  }
  p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-align: justify;

    color: #000000;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}
.featuredBlogs {
  background-image: url(../../../public/imgs/featured.png);
  background-color: black;
}
.blogCard {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  img {
    border-radius: 10px;
    width: 100%;
  }
  h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #000000;
    margin: 20px 0px;
    max-width: 270px;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.12em;
    color: #747474;
    margin-bottom: 0px;
  }
  .calender {
    width: 20px;
  }
  .arrow {
    width: 20px;
  }
  button {
    width: 105px;
    min-width: 100px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    margin-top: 0px;
    /* identical to box height, or 219% */

    color: #ffffff;
  }
}
.min-w300px {
  min-width: 200px !important;
}
.max-h628px {
  max-height: 614px;
  border-radius: 10px;
}
.photGallery {
  background: transparent !important;
  h1 {
    color: #000000;
  }
  p {
    color: #000000;
  }
  .imgContainer {
    overflow-x: hidden;
    .overlay {
      position: absolute;
      right: 0;
      width: 50%;
      bottom: 0;
      background: #0000006e;
      z-index: 9;
      top: 0;
      border-left: 3px solid white;
      border-radius: 10px;
      img {
        position: absolute;
        left: -17px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }
  }
}
.testimonialText {
  p {
    text-align: justify !important;
    max-width: 80%;
    margin: 0 auto;

    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;

    /* or 133% */
    text-align: justify;

    /* #184D47 */
    color: #184d47;
    h1 {
      font-family: "Poppins", sans-serif;
      font-style: italic;
      font-weight: 700;
      font-size: 22px;
      line-height: 57px;
      text-align: left;
      /* identical to box height, or 238% */

      color: #cc2939;
    }
  }
  .smallPera {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #525252;
  }
}
.testimonials {
  background: white;
  h1 {
    color: #000000;
  }
  .subTitile {
    color: #000000;
  }
  .testimonialImg {
    max-height: 500px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: inset 0px 10px 10px rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1));
    position: relative;
    z-index: 11;
  }
}
.react-multi-carousel-dot-list {
  justify-content: end !important;
}
.react-multi-carousel-dot button {
  min-width: 15px !important;
  min-height: 15px !important;
  border: none !important;
  background: #cc293970 !important;
}
.react-multi-carousel-dot--active button {
  background: #cc2939 !important;
}
.shadowBox {
  padding: 30px 30px 70px !important;
  @media screen and (max-width: 575px) {
    padding: 30px 0px 70px !important;
  }
}
.footerMain {
  .footer {
    background: #2b2e35;
    padding-top: 170px;
    margin-top: -140px;
    @media screen and (max-width: 575px) {
      margin-top: -100px;
    }
    .footerLogo {
      margin-left: -10px;
    }
    p {
      font-family: "SF Pro Display", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;

      color: #ffffff;
    }
    h6 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;

      /* identical to box height, or 162% */

      color: #ffffff;
    }
    h4 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 156.69%;

      /* or 28px */
      text-transform: uppercase;

      color: #d0d0d0;
      margin-bottom: 12px;
    }
    ul {
      padding-left: 0px;
      li {
        list-style: none;

        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;

        /* or 162% */

        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.virtualTours {
  background: #000000;
  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 54px;

    /* identical to box height */
    margin-top: 30px;
    margin-bottom: 0px;
    color: #ffffff;
  }
  button {
    background: #cc2939;
    border-radius: 6px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #cc2939;
    padding: 5px 10px;
    letter-spacing: -0.2px;

    /* icon/white */
    color: #ffffff;
  }
  .viewAllBlog {
    background: #cc2939;
    border-radius: 8px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    border: 1px solid #cc2939;
    /* identical to box height */
    min-width: 200px;
    color: #fff;
  }
  .textToShow {
    height: 400px;
    overflow: hidden;
    p {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 31px;
      margin-bottom: 30px;
      /* or 196% */
      text-align: justify;
      letter-spacing: 0.01em;

      /* white */
      color: #ffffff;
    }
  }
  .textToShow.opened {
    height: auto;
  }
  .readMoreBtn {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
    height: 150px;
    .blackDiv {
      background: linear-gradient(180deg, rgba(4, 5, 6, 0) 0%, #040506 39.06%);
      filter: blur(30px);
      height: 100%;
      position: absolute;
      width: 100%;
    }

    button {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.15px;
      background: #ffffff;
      border-radius: 8px;
      color: #cc2939;
      position: relative;
      border: 1px solid white;
      padding: 7px 20px;
      margin-top: 30px;
    }
  }

  .readMoreBtn.opened {
    margin-top: 0px;
    height: 50px;
  }
}
.propertyViewing {
  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: #cc2939;
    border-radius: 50%;
    background-size: 16px;
  }
  .carousel-caption {
    p {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;

      /* identical to box height */

      color: #ffffff;
    }
  }
  .carousel-indicators {
    display: none;
  }
  .propertyTags {
    background-color: #000000;
    padding: 40px 0px;
    .tagBox {
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding: 10px;
      h1 {
        font-family: "Mulish", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.2px;
        color: #ffffff;
      }
      p {
        font-family: "Mulish", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0px;
        /* identical to box height */
        letter-spacing: 0.2px;

        cursor: pointer;
        /* white */
        color: #ffffff;
      }
      span {
        font-family: "Mulish", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #ffffff;
        background: #cc2939;
        border-radius: 6px;
        padding: 5px;
      }
    }
  }
}
.interestingExperience {
  z-index: 9;
  position: relative;
  background: white;
  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 54px;
    text-transform: capitalize;
    color: #343434;
  }
  button {
    width: 280px;
    height: 68px;
    background: #cc2939;
    border-radius: 8px;
    border: 1px solid #cc2939;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #ffffff;
    padding: 0px 90px;
  }
}
.ratingMain {
  padding: 30px 0px;
  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 54px;
    text-transform: capitalize;
    color: #cc2939;
    text-align: center;
  }
  h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    border-bottom: 1px solid #cc2939;
    /* identical to box height, or 75% */
    letter-spacing: 1px;
    padding-bottom: 5px;
  }
  button {
    width: 100px;
    height: 35px;
  }
  .textToShow {
    height: 538px;
    overflow: hidden;
    padding-left: 20px;
    .list {
      padding: 20px 0px;
      border-bottom: 0.5px solid #74747471;
      img {
        width: 80px;
        height: 80px;
      }
      h4 {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 39px;
        /* identical to box height */

        margin-bottom: 0px;
      }
      p {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 33px;

        margin-bottom: 0px;
        color: #000000;
      }
      .reviewrRating {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .rating {
          @media screen and (max-width: 767px) {
            justify-content: end;
            margin-bottom: 10px;
            margin-top: 10px;
          }
        }
      }
      h6 {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0px;
        /* identical to box height */
        text-align: right;

        /* grey */
        color: #747474;
      }
      .rating.checked {
        color: #ffbc0b;
      }
    }
  }
  .textToShow.opened {
    height: auto;
  }
  .readMoreBtn {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -82px;
    height: 200px;
    .blackDiv {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 39.06%
      );
      filter: blur(30px);
      height: 100%;
      position: absolute;
      width: 100%;
    }
    button {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.15px;
      background: #cc2939;
      border-radius: 8px;
      color: white;
      position: relative;
      border: 1px solid #cc2939;
      padding: 7px 20px;
    }
  }
  .loadMore {
    button {
      width: 200px;
      margin-top: 50px;
    }
  }
}
.locationAdddress {
  background-color: #000000;
  padding: 50px 0px 100px 0px;
  position: relative;
  z-index: 9;
  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 20px;
    /* identical to box height, or 75% */
    letter-spacing: 1px;
    border-bottom: 0.5px solid #cc293994;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0px;
  }
  h6 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32 px;
    letter-spacing: 0.4px;
    margin-bottom: 0px;
    /* white */
    color: #ffffff;
  }
  .spacer {
    width: 1px;
    background: #cc293994;
    height: 60px;
  }
}
.aboutUsMain {
  .banner {
    background-image: url(../../../public/imgs/aboutus-bg.png);
    background-size: cover;
    min-height: 550px;
    .h-500px {
      height: 550px;
      h1 {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 76px;
        line-height: 144px;

        /* identical to box height */

        color: #ffffff;
        @media screen and (max-width: 575px) {
          font-size: 45px;
        }
      }
      p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        color: #ffffff;
      }
    }
  }
  .ourMission {
    // background: linear-gradient(180deg, #cf2333 4.74%, #8c0513 100%);
    background: white !important;
    padding: 50px 0px;
    h1 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 72px;

      /* identical to box height */

      /* white */
      color: #ffffff;
    }
    p {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;

      /* or 181% */

      /* white */
      color: #000000;
    }
  }
}
.contactUsMain {
  .contactUs {
    background-image: url(../../../public/imgs/contact-bg.png);
    background-size: cover;
    h1 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 76px;
      line-height: 100px;

      /* identical to box height */

      color: #ffffff;
      @media screen and (max-width: 575px) {
        font-size: 45px;
      }
    }
    p {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;

      color: #ffffff;
    }
    .contactCard {
      background: #ffffff;
      border-radius: 10px;
      padding: 20px;
      label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 29px;
        text-align: left !important;
        /* identical to box height, or 181% */

        color: #000000;
      }
      textarea {
        height: 119px;
      }
      .input-group {
        background: #eeeeee;
        border-radius: 8px !important;
        border: none !important;
        width: 100%;
        height: 100%;
        align-items: start;
        textarea {
          border: none !important;
          background: transparent;
        }
        span {
          padding-top: 10px;
        }
      }
      .addressInfo {
        background: #eeeeee;
        border-radius: 8px;
        padding: 20px;
        text-align: left;
        ul {
          padding-left: 15px;
          margin-bottom: 0px;
          li {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 29px;
            color: #343434;
          }
        }
      }
    }
    .haveQuestion {
      h1 {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 46px;
        line-height: 84px;
        margin-top: 40px;
        /* identical to box height */

        margin-bottom: 20px;
        color: #ffffff;
        @media screen and (max-width: 575px) {
          font-size: 27px;
          line-height: 50px;
        }
      }
      input.form-control {
        background: #ffffff !important;
        border-radius: 8px !important;
        height: 50px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 29px;
        border: none !important;
        /* identical to box height, or 181% */

        color: #000000 !important;
        &::placeholder {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 29px;

          /* identical to box height, or 181% */

          color: #000000 !important;
        }
      }
      textarea.form-control {
        background: #ffffff !important;
        border-radius: 8px !important;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 29px;
        border: none !important;
        /* identical to box height, or 181% */

        color: #000000 !important;
        &::placeholder {
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 29px;

          /* identical to box height, or 181% */

          color: #000000 !important;
        }
      }
    }
  }
}
.blogMain {
  .blogBanner {
    background-image: url(../../../public/imgs/blog-bg.png);
    background-size: cover;
    position: relative;
    .logo {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
    h1 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 76px;
      line-height: 100px;

      /* identical to box height */

      color: #ffffff;
      @media screen and (max-width: 575px) {
        font-size: 45px;
      }
    }
    .veticallyCentered {
      padding: 10% 0px;
      @media screen and (max-width: 767px) {
        padding: 15% 0px;
      }
      @media screen and (max-width: 575px) {
        padding: 100px 0px;
      }
    }
    .input-group {
      padding: 0px;
      background-color: white !important;
      border-radius: 8px !important;
      height: 50px;
      .input-group-text {
        padding: 0px !important;
        margin-right: -1px;
        margin-top: -1px;
        margin-bottom: -1px;
        button {
          height: 50px !important;
          width: 120px !important;
          font-family: "Poppins", sans-serif !important;
          font-style: normal;
          font-weight: 400 !important;
        }
      }
      input {
        padding-left: 20px;
      }
    }
    .blackTags {
      display: flex;
      gap: 10px;
      justify-content: center;
      flex-wrap: wrap;
      p {
        background: #2b2e35;
        border-radius: 10px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        /* identical to box height */
        color: #ffffff;
        padding: 10px 15px;
      }
    }
  }
}
.featuredArticles {
  padding: 50px 0px !important;
  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 0px;
    /* identical to box height */

    color: #2b2e35;
  }
  p.pera {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;

    /* identical to box height */

    color: #2b2e35;
  }
  .featuredArticlesList {
    background-image: none;
    .px-50px {
      @media screen and (max-width: 767px) {
        padding: 0px !important;
      }
    }
    .blogCard {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      h2 {
        max-width: 90%;
        @media screen and (max-width: 575px) {
          max-width: 100%;
        }
      }
      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.12em;
        color: #747474;
        margin-bottom: 0px;
      }
      .youtubeLogo {
        width: 100px;
      }
      button {
        width: 110px;
      }
      .ytIcon {
        width: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .allTours {
      text-align: left;
      margin-bottom: 40px;
    }
  }
  .pagination {
    display: inline-block;
  }

  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    font-family: "SF Pro Display", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    border: 1px solid #cc2939;
    border-right: none;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    text-align: center;

    color: #cc2939;
  }
  .pagination a:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .pagination a:last-child {
    border-right: 1px solid #cc2939;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .pagination a.active {
    background-color: #cc2939;
    color: white;
    border: 1px solid #cc2939;
    font-family: "SF Pro Display", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;

    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .pagination a:hover:not(.active) {
    background-color: #cc2939;
    color: white;
  }
  .reviewMain {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #cc2939;
    margin: 30px auto 0px;
    .cardSection {
      border: none;
      padding: 0px !important;
      .seperator {
        height: 1px;
        background: #9c9c9c88;
      }
      .back {
        border: 1px solid #cc2939;
        border-radius: 8px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 26px;
        background: transparent;
        /* identical to box height, or 219% */

        color: #cc2939;
        width: 88px;
        height: 40px;
      }
      .close {
        background: #cc2939;
        border-radius: 8px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 26px;
        border: 1px solid #cc2939;
        /* identical to box height, or 219% */
        width: 88px;
        height: 40px;
        color: #ffffff;
      }
      .d-flex.justify-content-between {
        flex-direction: row;
      }
    }
    .detailAboutBlog {
      display: flow-root;
      .detailBlogImg {
        float: left;
        height: 462px !important;
        width: 65% !important;
        margin-right: 20px;
        margin-bottom: 20px;
        img {
          object-fit: contain;
        }
        @media screen and (max-width: 1024px) {
          width: 100% !important;
        }
      }
      h1 {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 29px;

        /* identical to box height, or 161% */

        color: #000000;
      }
      img.fountain {
        border-radius: 8px;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        text-align: left !important;
        text-align: center;
        letter-spacing: 0.12em;
        margin-bottom: 0px;
        color: #747474;
      }
      p.description {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        margin-top: 20px;
        /* or 139% */
        text-align: justify;

        color: #747474;
      }
      .divi {
        background: #747474;
        width: 2px;
        height: 15px;
      }
      .profileName {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        h6 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 30px;

          /* or 231% */
          margin-bottom: 0px;
          color: #747474;
        }
      }
    }
  }
}
.form-select:disabled {
  background: transparent !important;
}
.dashboardMain {
  .dashCard {
    padding: 30px 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
    i {
      font-size: 50px;
      color: #cc2939;
      margin-bottom: 10px;
    }
    h1 {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 19px;
      color: #343434;
      margin-bottom: 0px;
    }
    h2 {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #343434;
      margin-bottom: 0px;
    }
    p {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 19px;
      color: #cc2939;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
}
.outTitle {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  /* identical to box height */
  letter-spacing: 0.4px;

  /* grayscale / black */
  color: #252733;
}
.questionareDetailForm {
  label {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    /* identical to box height */
    letter-spacing: 0.4px;

    /* grayscale / black */
    color: #252733;
  }
  input[type="text"] {
    border: none !important;
    border-bottom: 1px solid #c2c2c2 !important;
    border-radius: 0px !important;
  }
  h3 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;

    /* identical to box height */
    letter-spacing: 0.4px;

    /* grayscale / black */
    color: #252733;
  }
  hr {
    margin: 0px -20px;
  }
  .form-check {
    .form-check-input {
      border-color: #cc2939;
    }
    label {
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;

      /* identical to box height */
      letter-spacing: 0.4px;

      /* grayscale / black */
      color: #252733;
    }
  }

  .form-check-input:checked {
    background: #cc2939 !important;
    border-color: #cc2939;
  }
  .questions {
    input {
      border: 1px solid #00000095 !important;
    }
  }
}
.rdt_TableHeadRow {
  justify-content: space-between !important;
}
.rdt_TableRow {
  input {
    border-color: #000000 !important;
    width: 1.2rem !important;
    height: 1.2rem !important;
    margin-top: 13px;
    &:focus {
      box-shadow: none !important;
    }
  }
  input:checked {
    width: 1.2rem !important;
    height: 1.2rem !important;
    background: #03f690 !important;
    border-color: #000000 !important;
    padding: 5px;
    margin-top: 13px;
    &:focus {
      box-shadow: none !important;
    }
  }
  input[type="checkbox"] {
    height: initial !important;
  }
}
.radioStatus {
  .radioBtns {
    text-align: center;
    width: 120px !important;
    p {
      display: inline-block;
    }
  }
}
.inActive {
  input {
    margin-top: 0px !important;
  }
}
.openSidebar.mainOverlay {
  @media screen and (max-width: 992px) {
    position: fixed;
    background: #0000008a;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
}
.mapBox {
  .openlayers-map {
    height: 500px;
  }
}
.orderProductPlan {
  .row.dividerRow {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 13px;
  }
  h1 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    /* identical to box height */
    text-transform: capitalize;

    /* Dark Grey */
    color: #343434;
  }
  h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    margin-bottom: 20px;
    /* Dark Grey */
    color: #343434;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* Dark Grey */
    color: #343434;
  }
  span {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    /* identical to box height */
    letter-spacing: 0.2px;

    /* Dark Grey */
    color: #343434;
    background: #ffcfd4;
    border-radius: 60px;
    padding: 5px 10px;
    display: block;
    width: fit-content;
    margin-bottom: 20px;
  }
  .priceMain {
    p {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;

      /* identical to box height */
      margin-bottom: 0px;
      /* Dark Grey */
      color: #343434;
    }
    button {
      background: #cc2939;
      border-radius: 19px;
      border: none;

      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;

      /* identical to box height */
      padding: 5px 30px;
      padding-bottom: 7px;
      /* white */
      color: #ffffff;
    }
  }
  .getQuoted {
    background: #fffbfb;
    span {
      background: #fff4bd;
    }
    .priceMain {
      button {
        background: #fede38;
        color: #343434;
        text-transform: capitalize;
        padding-bottom: 7px;
      }
    }
  }
}
.orderProductPlanModal {
  .avatar-preview {
    .form-check-input {
      border: 1.5px solid #d9d9d9 !important;
    }
  }
  .loginBtn.type {
    border-radius: 20px;
    height: 32px;
    width: 63px;
  }
}
.mt-25px {
  margin-top: 25px !important;
}
input[type="file"] {
  margin-top: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.poppins {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}
.w-230 {
  width: 230px;
}
.innerTitle {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  margin-bottom: 0px;
  letter-spacing: 0.4px;

  /* grayscale / black */

  color: #252733;
}
.userManagement {
  span {
    i.fa-angle-down {
      display: block;
    }
    div {
      i.fa-angle-down {
        display: none;
      }
    }
  }
  span.active {
    i.fa-angle-down {
      display: none;
    }
    div {
      display: flex;
      align-items: center;
      width: 100%;
      i.fa-angle-down {
        display: block;
        position: absolute;
        right: 44px;
      }
    }
  }
}
.btn:disabled {
  background-color: lightgray !important;
  border: lightgray !important;
}
.upgradeAccount {
  h5 {
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 35px;
  }
  .card {
    padding: 0;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin: 10px 0;
    .plan {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px;
      .name-price {
        h4 {
          font-size: 26px;
          line-height: 30px;
          text-transform: capitalize;
          font-family: "Roboto", sans-serif;
          margin-bottom: 20px;
          font-weight: 700;
        }
        h3 {
          font-weight: 600;
          font-size: 40px;
          line-height: 47px;
          display: flex;
          align-items: center;
          color: #cc2939;
          font-family: "Roboto", sans-serif;
        }
      }
      span {
        background: #fede38;
        border-radius: 60px;
        color: #000;
        font-weight: 700;
        padding: 4px 10px;
        font-size: 12px;
      }
    }
    .plan-detail {
      padding: 20px;
      border-top: 0.5px solid #d9d9d9;
      border-bottom: 0.5px solid #d9d9d9;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          font-weight: 400;
          font-size: 14px;
          color: #343434;
          font-family: "Roboto", sans-serif;
          img {
            width: 20px;
            margin-right: 10px;
          }
        }
      }
    }
    .subscribe {
      padding: 25px 20px;
      text-align: center;
      .btn {
        background: #cc2939;
        border-radius: 60px;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #fff;
        font-family: "Roboto", sans-serif;
        padding: 8px 28px;
      }
    }
  }
}
.staffMainDiv {
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding: 20px;
  .d-flex.justify-content-between {
    @media screen and (max-width: 575px) {
      flex-direction: column;
      gap: 10px;
    }
  }
  a {
    .loginBtn {
      width: auto;
    }
  }
  .input-group {
    padding: 0px;
    border-radius: 30px !important;
    img {
      margin-top: 0px !important;
    }
  }
  ul {
    padding-left: 0px;
    li {
      background: #ffffff;
      box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      padding: 10px;
      list-style: none;
      margin-bottom: 15px;
      position: relative;
      a {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        width: 40px;
        font-family: "Mulish", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.2px;

        text-decoration: none;
        /* grey */
        color: #747474;
      }
    }
  }
  .accordion {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .accordion-item {
      border: 1px solid #c9c9c9;
      position: relative;
      .button-wrapper {
        position: absolute;
        right: 15px;
        top: 20px;
        display: flex;
        gap: 10px;
        z-index: 9;
        cursor: pointer;
        img {
          width: 18px;
        }
      }
      .accordion-button {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.2px;
        justify-content: left;
        padding: 1rem;
        &::after {
          display: none;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .accordion-button:not(.collapsed) {
        background: transparent;
        box-shadow: none;
        padding: 1rem;
        justify-content: left;
      }
    }
    .accordion-body {
      padding: 1rem;
    }
  }
}
.commission_rangePicker {
  input {
    border: 1px solid #747474 !important;
    border-radius: 100px;
    padding: 5px 10px;
    height: 45px;
    margin-right: 15px;
  }
}
.answerTypeFont {
  .form-check {
    label {
      font-size: 16px;
    }
  }
}
.quill {
  .ql-container {
    height: 300px;
  }
}

.review-comments {
  height: 450px !important;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }
}
