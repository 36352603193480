@font-face {
  font-family: "Mulish";
  src: url("../fonts/Mulish-Regular.woff2") format("woff2"),
    url("../fonts/Mulish-Regular.woff") format("woff"),
    url("../fonts/Mulish-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter-Regular.woff") format("woff"),
    url("../fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff"),
    url("../fonts/SFProDisplay-Regular.ttf") format("truetype");
}
a {
  text-decoration: none !important;
}

.credit-score {
  width: auto;
  height: 15px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  top: 9px;
  letter-spacing: 0.2px;
  color: #343434;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.credit-score-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 16px;
  position: relative;
  left: 12px;
  width: auto;
  height: 23px;
  background-color: #ffcfd4;
  border-radius: 28px;
  /* Inside auto layout */
  flex: none;
  order: 6;
  flex-grow: 0;
  z-index: 6;
}

@media screen and (min-height: 700px) {
  .loginMain {
    height: 100vh;
  }
}
.loginMain .logo {
  background: white;
  border-radius: 30px;
  padding: 5px 20px;
}
.loginMain .loginBox {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
}
.loginMain .loginBox h3 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #252733;
}
.loginMain .loginBox p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0000ff;
  cursor: pointer;
}

.cancelBtn {
  border: 1px solid #747474;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #747474;
  width: 81px;
  height: 40px;
  background-color: transparent;
}
.cancelBtn:hover {
  background-color: #747474 !important;
  color: white !important;
  border: 1px solid #747474 !important;
}

.cancelBtn.filled {
  background-color: #747474 !important;
  color: white !important;
  border: 1px solid #747474 !important;
}
.cancelBtn.filled:hover {
  background-color: transparent !important;
  color: #747474 !important;
  border: 1px solid #747474 !important;
}

.registerBtn {
  border: 1px solid #747474;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  width: 81px;
  height: 40px;
  background-color: #343434;
  color: white;
  border: 1px solid #343434;
}
.registerBtn:hover {
  background-color: transparent !important;
  color: #343434 !important;
  border: 1px solid #343434 !important;
}

.loginBtn {
  border: 1px solid #cc2939;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.2px;
  color: white;
  width: 81px;
  height: 40px;
  background-color: #cc2939;
}
.loginBtn:hover {
  background-color: transparent !important;
  color: #cc2939 !important;
  border: 1px solid #cc2939 !important;
}

.w-100px {
  width: 100px !important;
}

.closeBtn {
  border: 1px solid #343434;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.2px;
  color: white;
  width: 81px;
  height: 40px;
  background-color: #343434;
}
.closeBtn:hover {
  background-color: transparent !important;
  color: #343434 !important;
  border: 1px solid #343434 !important;
}

.form-select {
  border: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474;
  position: absolute !important;
  padding-left: 52px !important;
  background-color: transparent !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
}
.form-select:focus {
  box-shadow: none;
}

label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #747474;
}

input.form-control {
  border: 1px solid #747474 !important;
  border-radius: 4px !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474 !important;
  background: transparent;
}
input.form-control::-moz-placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474;
}
input.form-control::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474;
}
input.form-control:focus {
  box-shadow: none !important;
  background: transparent;
}

.form-control:disabled {
  background: transparent;
}

.input-group {
  border: 1px solid #747474 !important;
  border-radius: 4px !important;
  padding: 5px 0px;
  height: 45px;
}
.input-group .input-group-text {
  background: transparent;
  border: none;
}
.input-group .input-group-text img {
  width: 20px;
  margin-top: -2px;
}
.input-group input {
  border: none !important;
  padding-left: 0px;
}

.fa-bars {
  position: fixed;
  z-index: 22222;
  left: 10px;
  top: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in;
}

.fa-bars.openSidebar {
  left: 280px;
}

.sideBar {
  position: fixed;
  background: #f1f1f1;
  width: 270px;
  height: 100%;
  padding: 20px;
  margin-left: 0;
  transition: all 0.5s ease-in;
}
.sideBar h1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #343434;
  margin-bottom: 2px;
}
.sideBar h5 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #cc2939;
  margin-bottom: 0px;
}
.sideBar .dpImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.sideBar ul {
  padding-left: 0px;
}
.sideBar ul i {
  width: 20px;
  margin-right: 10px;
  margin-top: -3px;
  color: #343434;
}
.sideBar ul li {
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #343434;
  margin-bottom: 0px;
  text-decoration: none;
  cursor: pointer;
}
.sideBar ul li ul {
  padding-left: 30px;
  margin-top: 10px;
}
.sideBar ul li ul a {
  text-decoration: none;
}
.sideBar ul li ul li {
  margin-bottom: 0px;
  padding-left: 10px;
  padding: 13px;
}
.sideBar ul li ul li.active {
  background: #ffcfd4;
  border-radius: 10px;
  color: black;
  width: 150px;
}
.sideBar ul li.active {
  color: white;
  background: #cc2939;
  border-radius: 10px;
  padding: 13px;
  display: inline-block;
}
.sideBar ul li.active i {
  color: white;
}
.sideBar ul li span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
}
.sideBar ul li span i.fa-angle-down {
  visibility: visible;
}
.sideBar ul li span div i.fa-angle-down {
  visibility: hidden !important;
}
.sideBar ul li span.active i.fa-angle-down {
  visibility: hidden;
}
.sideBar ul li span.active div {
  color: white;
  background: #cc2939;
  border-radius: 10px;
  padding: 13px;
  display: flex;
  align-items: baseline;
}
.sideBar ul li span.active div i {
  color: white;
  visibility: visible !important;
}
.sideBar ul li span.active div .fa-angle-down {
  margin-right: 0px;
}
.sideBar .signOut {
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #343434;
  margin-bottom: 0px;
  bottom: 30px;
  cursor: pointer;
}
.sideBar .signOut img {
  width: 20px;
  margin-right: 10px;
  margin-top: -3px;
}

.openSidebar {
  margin-left: 0px;
  z-index: 99;
}

.closeSidebar {
  margin-left: -270px;
  z-index: 99;
}

.sidePage {
  margin-left: 270px;
}
.sidePage .px-sm-5.py-4 {
  padding-top: 80px !important;
}

.sidePage.openSidebar {
  margin-left: 270px;
  transition: all 0.5s ease-in;
}
@media screen and (max-width: 992px) {
  .sidePage.openSidebar {
    margin-left: 0px;
  }
}

.sidePage.closeSidebar {
  margin-left: 0px;
  transition: all 0.5s ease-in;
}

.breadCrumb h3 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}

.cardSection {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 20px;
}
.cardSection h1 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #252733;
}
.cardSection .profileImg {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.forgotPassword {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0000ff;
  cursor: pointer;
}

.identifier {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #252733;
}

.signupMain {
  background-image: url(../../../public/imgs/signup-bg.png);
  background-size: cover;
  min-height: 100vh;
}
.signupMain .logo {
  background: white;
  border-radius: 30px;
  padding: 5px 20px;
}
.signupMain h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 54px;
  color: #ffffff;
  text-align: center;
}
.signupMain p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
}
.signupMain .loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 30%;
  height: 30%;
  background-color: rgba(248, 248, 248, 0.6784313725);
}
.signupMain .SignupBox {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #a6444d;
  border-radius: 20px;
  padding: 40px;
}
.signupMain .SignupBox label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  /* identical to box height */
  color: #ffffff !important;
}
.signupMain .SignupBox input {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  /* identical to box height */
  color: white !important;
}
.signupMain .SignupBox input::-moz-placeholder {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  color: rgba(255, 255, 255, 0.5);
}
.signupMain .SignupBox input::placeholder {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  color: rgba(255, 255, 255, 0.5);
}
.signupMain .SignupBox .form-check-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-left: 10px !important;
  /* identical to box height */
  text-transform: capitalize;
  color: #ffffff;
}
.signupMain .SignupBox .form-check-input {
  border: 1px solid #747474 !important;
  height: 20px;
  width: 20px;
}
.signupMain .SignupBox .form-check-input:focus {
  box-shadow: none;
}
.signupMain .SignupBox .forgotpassword {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
}
.signupMain .SignupBox .forgotpassword a {
  color: white;
  text-decoration: none;
}

.rdt_Table img {
  cursor: pointer;
}

.rdt_TableCol div {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.2px;
  /* grayscale / gray */
  color: #9fa2b4;
}

.rdt_TableCell {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.rdt_TableCell div {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.2px;
  overflow: inherit !important;
  /* grayscale / black */
  color: #252733;
}

.status {
  border-radius: 60px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 3px 10px;
  /* identical to box height */
  letter-spacing: 0.2px;
  margin-top: 10px;
}

.status.active {
  background: #03f690;
  color: #000000;
}

.status.active1 {
  background: gray;
  color: #ffffff;
}

.status.draft {
  background: #fede38;
  color: #000000;
}

.status.draft1 {
  background: #fede38;
  color: #ffffff;
}

.status.revoked {
  background: #f6034c;
  color: white;
}

.status.published {
  background: green;
  color: white;
}

.status.score {
  background: blue;
  color: white;
}

.status.hide {
  background: #343434;
  color: white;
}

.rdt_TableRow {
  border-bottom: none !important;
  justify-content: space-between !important;
}

.rdt_Pagination {
  margin-top: 20px;
  border: none !important;
}

.tblCheckBox {
  text-align: right;
}
.tblCheckBox input {
  border: 2px solid #747474;
  border-radius: 0px !important;
}

.table-bordered th {
  border: 1px solid #747474;
}
.table-bordered td {
  border: 1px solid #747474 !important;
}

.w-170 {
  width: 170px;
}

.rdw-editor-wrapper {
  border: 1px solid #747474;
  border-radius: 5px;
  padding: 5px;
}
.rdw-editor-wrapper .rdw-editor-main {
  padding: 20px 10px;
  min-height: 300px;
}
.rdw-editor-wrapper .rdw-block-wrapper,
.rdw-editor-wrapper .rdw-fontsize-wrapper,
.rdw-editor-wrapper .rdw-fontfamily-wrapper {
  display: none !important;
}

.ReactTags__tags.react-tags-wrapper {
  border: 1px solid #747474;
  border-radius: 6px;
  padding: 10px;
}
.ReactTags__tags.react-tags-wrapper .ReactTags__tag {
  background: #cc2939;
  border-radius: 6px;
  padding: 5px;
  padding-left: 8px;
  margin-right: 5px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.2px;
  /* white */
  color: #ffffff;
}
.ReactTags__tags.react-tags-wrapper .ReactTags__tag button {
  background: transparent;
  border: none;
  color: white;
}
.ReactTags__tags.react-tags-wrapper input {
  border: none;
  margin-top: 5px;
}
.ReactTags__tags.react-tags-wrapper input:focus-visible {
  outline: none;
}

.coverImg h5 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.2px;
  color: #000000;
}

.avatar-edit {
  position: relative;
  width: 294px;
  height: 184px;
}
@media screen and (max-width: 575px) {
  .avatar-edit {
    width: 100%;
  }
}
.avatar-edit img {
  width: 100%;
  height: 100%;
}
.avatar-edit #imageUpload {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  cursor: pointer;
}

#imagePreview img {
  border-radius: 6px;
  width: 294px;
  height: 184px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media screen and (max-width: 575px) {
  #imagePreview img {
    width: 100%;
  }
}

#imagePreview1 img {
  border-radius: 6px;
  width: 212px;
  height: 116px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media screen and (max-width: 575px) {
  #imagePreview1 img {
    width: 100%;
  }
}

.imagePreviewModal img {
  width: 100%;
  border-radius: 5px;
  height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
}

.image-placeholder {
  display: flex;
  gap: 20px;
}
.image-placeholder input {
  background: #f4f4f4;
  border: none;
  margin-top: 5px;
}
@media screen and (max-width: 767px) {
  .image-placeholder {
    flex-wrap: wrap;
  }
}

.avatar-preview {
  position: relative;
}
.avatar-preview .delImg {
  position: absolute;
  right: 10px;
  top: 10px;
}
.avatar-preview .form-check {
  margin-left: 10px;
  margin-top: 10px;
}
.avatar-preview .form-check-input {
  border-color: #cc2939;
  border-radius: 50%;
  margin-top: 6px;
}
.avatar-preview .form-check-input:focus {
  box-shadow: none;
}
.avatar-preview .form-check-input:checked {
  background-color: #cc2939;
  border-color: #cc2939;
}
.avatar-preview .form-check-label {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.2px;
  color: #000000;
}

.modal-content .closeIcon {
  cursor: pointer;
}

.modal-body h1 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}

textarea {
  border: 1px solid #747474 !important;
  resize: none;
}
textarea:focus {
  box-shadow: none !important;
  outline: none;
}

.reference {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.2px;
  color: #000000;
}

.modal-dialog {
  max-width: 700px;
}

.sideMenuheight {
  height: 75vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.sideMenuheight::-webkit-scrollbar {
  display: none !important;
}

:root {
  scrollbar-color: grey;
  scrollbar-width: 5px;
}

.uploadImg {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* grey */
  color: #747474;
}

.uploadImgMain {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin: 0 auto;
}

.uploadImgIcon {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0);
}

.borderDashed {
  border: 1px dashed #747474;
  border-radius: 5px;
  padding: 20px;
}
.borderDashed h1 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}
.borderDashed h2 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}
.borderDashed .uploadBtnDesign {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.borderDashed .uploadBtnDesign input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.borderDashed .uploadBtnDesign button {
  cursor: pointer;
  z-index: 0;
  position: relative;
}

.ugradePlanEditor .rdw-editor-wrapper {
  border: 1px solid #d9d9d9;
}

.recommended .form-check-input {
  background: #d9d9d9;
  border-radius: 2px;
  border: none;
  margin-top: 8px;
}
.recommended .form-check-input:focus {
  box-shadow: none;
}
.recommended .form-check-input:checked[type="radio"] {
  background-color: #cc2939;
}

.pageManagement a {
  text-decoration: none;
}
.pageManagement .block {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px rgba(0, 0, 0, 0.0392156863);
  transition: all 0.3s ease;
}
.pageManagement .block:hover {
  box-shadow: 11px 8px 6px 0px rgba(0, 0, 0, 0.0392156863);
}
.pageManagement .block h1 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-decoration: none;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grey */
  color: #747474;
}

.accordion .accordion-item {
  border: none;
}
.accordion .accordion-item .accordion-button {
  justify-content: center;
  border: none;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.2px;
  color: #0000ff;
}
.accordion .accordion-item .accordion-button::after {
  display: none;
}
.accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion .accordion-item .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none;
}
.accordion .accordion-body {
  padding: 0px;
}

.adressCheckbox .form-check-input[type="checkbox"] {
  border: 1.5px solid #0832de;
  border-radius: 0px !important;
  margin-top: 7px;
}

.articleDetail p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
  /* identical to box height, or 150% */
  border-bottom: 0.5px solid #747474;
  padding: 10px;
  color: #747474;
}
.articleDetail .tag {
  background: #cc2939;
  border-radius: 6px;
  padding: 5px;
  padding-left: 5px;
  padding-left: 8px;
  margin-right: 5px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: auto;
}
.articleDetail .accordion .accordion-item {
  margin-bottom: 20px;
}
.articleDetail .accordion .accordion-item .accordion-button {
  justify-content: start;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17.2082px;
  line-height: 22px;
  letter-spacing: 0.382405px;
  color: #252733;
  border: 0.956012px solid #d9d9d9;
  padding: 15px 17px;
  border-radius: 5px;
}
.articleDetail .accordion .accordion-item .accordion-button::after {
  display: block;
  background-size: 12px !important;
  width: 12px !important;
  height: 12px !important;
}
.articleDetail .accordion .accordion-item .apexcharts-canvas {
  background: rgba(211, 211, 211, 0.231372549);
}
.articleDetail .accordion .accordion-item .accordion-body {
  padding: 0px;
  border: 0.956012px solid #d9d9d9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.articleDetail .accordion .accordion-item .ratingReviews .d-flex {
  align-items: center;
}
.articleDetail .accordion .accordion-item .ratingReviews .input-group {
  width: 250px;
}
.articleDetail .accordion .accordion-item .ratingReviews p {
  padding: 0px;
  border: none;
}
.articleDetail .accordion .accordion-item .ratingReviews p span {
  font-size: 30px;
  color: #343434;
}
.articleDetail .accordion .accordion-item .ratingReviews p div {
  text-align: center;
}

.rating {
  display: flex;
  gap: 5px;
}
.rating .checked {
  color: #fede38;
}

.reviewrsList {
  border-top: 0.956012px solid #d9d9d9;
  padding: 10px;
}
.reviewrsList img {
  width: 65px;
  height: 65px;
}
.reviewrsList .data {
  max-width: 75%;
}
.reviewrsList h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15.2962px;
  line-height: 23px;
  color: #000000;
}
.reviewrsList p {
  padding: 0px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15.2962px;
  line-height: 23px;
  border: none;
  color: #000000;
  margin-top: 5px;
}

.approvalForm .subTitle {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.2px;
  color: #000000;
}
.approvalForm input.form-control {
  height: 48px;
}
.approvalForm select {
  border: 1px solid #747474;
  border-radius: 6px;
  height: 48px;
}
.approvalForm .loginBtn {
  height: 48px;
  margin-top: 29px;
}

video {
  border-radius: 10px;
}

.thumbnail h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #343434;
}
.thumbnail p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #747474;
  margin-bottom: 0px;
}
.thumbnail #imagePreview img {
  width: 116px;
  height: 60px;
  border-radius: 5px;
}
.thumbnail .avatar-edit {
  width: 116px;
  height: 60px;
}

.score {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}

.questions h1 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}
.questions .answerBox textarea {
  background: #ffffff;
  border: 1px solid #000000 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.questions .answerBox textarea:focus {
  border: 1px solid #000000 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.articleReply img {
  width: 50px;
}
.articleReply h5 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.2px;
  margin-bottom: 0px;
  /* grayscale / black */
  color: #252733;
}
.articleReply h6 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.2px;
  margin-bottom: 0px;
  /* grayscale / black */
  color: #252733;
}
.articleReply .rating {
  display: flex;
  gap: 1px;
}
.articleReply .rating .fa {
  font-size: 10px;
  line-height: 19px;
}
.articleReply .rating .fa.checked {
  color: #cc2939;
}
.articleReply p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  /* or 171% */
  /* grey */
  color: #747474;
}
.articleReply .replyDiv {
  background: #f1f1f1;
  border-radius: 4px;
  padding: 5px 10px;
  width: 96%;
  margin-left: auto;
}
.articleReply .replyDiv img {
  width: 20px;
  height: 20px;
}
.articleReply .replyDiv input {
  border: none !important;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grey */
  color: #747474;
}
.articleReply .replyDiv input::-moz-placeholder {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grey */
  color: #747474;
}
.articleReply .replyDiv input::placeholder {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grey */
  color: #747474;
}
.articleReply .replyDiv .loginBtn {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  width: 47px;
  height: 20px;
  border-radius: 30px;
  text-align: center;
  letter-spacing: 0.15px;
  padding: 2px;
  /* white */
  color: #ffffff;
}

@media screen and (max-width: 575px) {
  .cardSection > .d-flex.justify-content-between {
    flex-direction: column;
  }
}
.slider h1 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.4px;
  margin-bottom: 0px;
  /* grayscale / black */
  color: #252733;
}
.slider p {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: gray;
}
.slider p span {
  color: black;
}
.slider .carousel-control-prev,
.slider .carousel-control-next {
  opacity: 1;
}
.slider .carousel-control-prev span,
.slider .carousel-control-next span {
  background-color: #cc2939;
  border-radius: 50%;
  background-size: 48%;
  opacity: 1;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.carousel-indicators {
  margin-bottom: -30px !important;
}
.carousel-indicators button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  opacity: 1 !important;
  background-color: lightgray !important;
}
.carousel-indicators button.active {
  background-color: #cc2939 !important;
}

.productDetailBtn {
  margin-top: 31px;
  height: 46px;
}

.editIcon {
  width: 32px;
  height: 32px;
}

.breadCrumb {
  margin-bottom: 0px;
}
.breadCrumb .breadcrumb-item a {
  text-decoration: none;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}
.breadCrumb .breadcrumb-item.active {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grey */
  color: #747474;
}

.forgetPasswordBtn {
  width: 147px;
  height: 30px;
}

.headerMain {
  position: fixed;
  left: 270px;
  right: 0;
  background: white;
  transition: all ease-in 0.5s;
  top: 0;
  z-index: 9;
}
@media screen and (max-width: 992px) {
  .headerMain {
    left: 0px !important;
  }
}

.sidePage.closeSidebar .headerMain {
  left: 0px;
}
@media screen and (max-width: 992px) {
  .sidePage.closeSidebar .headerMain {
    left: 0px !important;
  }
}

.header .logo {
  width: 160px;
}
.header .btn-default {
  border: 0;
  padding: 8px;
}
.header .btn-default .btn-primary {
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
.header .btn-default .dropdown-menu {
  left: -91px !important;
  text-align: center;
  width: 180px;
  padding: 15px;
}
.header a {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-decoration: none;
  /* identical to box height */
  padding-right: 5px !important;
  padding-left: 5px !important;
  color: #000000;
}
.header .notifBg {
  background: rgba(208, 210, 218, 0.1);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .notifBg img {
  width: 18px;
}
.header h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
  /* identical to box height, or 150% */
  color: #ffffff;
}
.header p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
  /* identical to box height, or 150% */
  /* Greys/Blue Grey/600 */
  color: #a1a5b6;
}
.header .profileImg {
  width: 40px;
  height: 40px;
}

.navbar-toggler {
  border: none;
  position: absolute;
  right: 90px;
  top: 10px;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler i {
  color: black;
  position: static;
}

.withoutLogin .navbar-toggler {
  position: absolute;
  right: 50px;
}

@media screen and (max-width: 1190px) {
  .w-md-100 {
    width: 100%;
  }
}

.px-50px {
  padding: 0 50px;
}
@media screen and (max-width: 767px) {
  .px-50px {
    padding: 0 20px;
  }
}

.container-fluid {
  position: relative;
}

@media screen and (max-width: 992px) {
  .dropdown {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}
.dropdown .dropdown-toggle {
  background: transparent;
  border: none;
  padding: 0px;
}
.dropdown .dropdown-toggle:hover {
  background: transparent;
}
.dropdown .dropdown-toggle::after {
  display: none;
}
.dropdown .dropdown-toggle .profileImg {
  border-radius: 100px;
}
.dropdown .dropdown-menu {
  left: -91px !important;
  text-align: center;
  width: 215px;
  padding: 30px;
}
.dropdown .dropdown-menu .profileImgInner {
  border-radius: 100px;
  display: block;
  margin: 0 auto 13px auto;
  width: 60px;
  height: 60px;
}
.dropdown .dropdown-menu .name {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
}
.dropdown .dropdown-menu .contributor {
  border: 1px solid #cc2939;
  border-radius: 60px;
  padding: 8px 12px;
  display: inline-block;
  margin: 5px 0 20px 0;
}
.dropdown .dropdown-menu .contributor p {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #000000;
}
.dropdown .dropdown-menu .dropdown-item {
  background: #fff;
  border-radius: 10px;
  color: #343434;
  margin-top: 5px;
  padding: 10px;
}
.dropdown .dropdown-menu .dropdown-item img {
  margin-right: 5px;
}
.dropdown .dropdown-menu .dropdown-item:hover {
  background: #0832de;
  border-radius: 10px;
  color: #fff;
  margin-top: 5px;
}
.dropdown .dropdown-menu .dropdown-item:hover img {
  filter: invert(1);
}
.dropdown .dropdown-menu .active {
  background: #0832de;
  border-radius: 10px;
  color: #fff;
}
.dropdown .dropdown-menu .active img {
  filter: invert(1);
}

.bannerSection {
  background-image: url(../../../public/imgs/banner.png);
  background-size: cover;
  padding: 5% 0px;
}
.bannerSection h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 54px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}
.bannerSection p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
}
.bannerSection button {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  border: 1px solid #cc2939;
  margin-top: 20px;
  background: #cc2939;
  border-radius: 8px;
  color: #ffffff;
  min-width: 300px;
  height: 45px;
}
.bannerSection .viewAllBlog {
  background: #ffffff;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  border: 1px solid white;
  /* identical to box height */
  min-width: 200px;
  color: #000000;
}

.tourMain {
  padding: 20px;
  min-height: 400px;
  position: relative;
  border-radius: 10px;
}
.tourMain img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  z-index: 0;
  cursor: pointer;
}
.tourMain button {
  position: relative;
  width: auto;
  min-width: auto;
  padding: 0px 10px;
  font-size: 14px;
  height: 40px;
  margin-top: 0px;
}
.tourMain .bottomText {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
}
.tourMain .bottomText h3 {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}
.tourMain .bottomText h6 {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.tourMainCard {
  padding: 20px;
  min-height: 250px;
  position: relative;
  border-radius: 10px;
}
.tourMainCard img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  z-index: 0;
}
.tourMainCard button {
  position: relative;
  width: auto;
  min-width: auto;
  padding: 0px 10px;
  font-size: 14px;
  height: 40px;
  margin-top: 0px;
}
.tourMainCard .bottomText {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
}
.tourMainCard .bottomText h3 {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}
.tourMainCard .bottomText h6 {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.aboutSection h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 72px;
  margin-bottom: 20px;
  /* identical to box height */
  color: #000000;
}
@media screen and (max-width: 767px) {
  .aboutSection h1 {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}
.aboutSection p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: justify;
  color: #000000;
}
@media screen and (max-width: 767px) {
  .aboutSection p {
    font-size: 14px;
  }
}

.featuredBlogs {
  background-image: url(../../../public/imgs/featured.png);
  background-color: black;
}

.blogCard {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
.blogCard img {
  border-radius: 10px;
  width: 100%;
}
.blogCard h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #000000;
  margin: 20px 0px;
  max-width: 270px;
}
.blogCard p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #747474;
  margin-bottom: 0px;
}
.blogCard .calender {
  width: 20px;
}
.blogCard .arrow {
  width: 20px;
}
.blogCard button {
  width: 105px;
  min-width: 100px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  margin-top: 0px;
  /* identical to box height, or 219% */
  color: #ffffff;
}

.min-w300px {
  min-width: 200px !important;
}

.max-h628px {
  max-height: 614px;
  border-radius: 10px;
}

.photGallery {
  background: transparent !important;
}
.photGallery h1 {
  color: #000000;
}
.photGallery p {
  color: #000000;
}
.photGallery .imgContainer {
  overflow-x: hidden;
}
.photGallery .imgContainer .overlay {
  position: absolute;
  right: 0;
  width: 50%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.431372549);
  z-index: 9;
  top: 0;
  border-left: 3px solid white;
  border-radius: 10px;
}
.photGallery .imgContainer .overlay img {
  position: absolute;
  left: -17px;
  top: 50%;
  transform: translate(0%, -50%);
}

.testimonialText p {
  text-align: justify !important;
  max-width: 80%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  /* or 133% */
  text-align: justify;
  /* #184D47 */
  color: #184d47;
}
.testimonialText p h1 {
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 22px;
  line-height: 57px;
  text-align: left;
  /* identical to box height, or 238% */
  color: #cc2939;
}
.testimonialText .smallPera {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #525252;
}

.testimonials {
  background: white;
}
.testimonials h1 {
  color: #000000;
}
.testimonials .subTitile {
  color: #000000;
}
.testimonials .testimonialImg {
  max-height: 500px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: inset 0px 10px 10px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1));
  position: relative;
  z-index: 11;
}

.react-multi-carousel-dot-list {
  justify-content: end !important;
}

.react-multi-carousel-dot button {
  min-width: 15px !important;
  min-height: 15px !important;
  border: none !important;
  background: rgba(204, 41, 57, 0.4392156863) !important;
}

.react-multi-carousel-dot--active button {
  background: #cc2939 !important;
}

.shadowBox {
  padding: 30px 30px 70px !important;
}
@media screen and (max-width: 575px) {
  .shadowBox {
    padding: 30px 0px 70px !important;
  }
}

.footerMain .footer {
  background: #2b2e35;
  padding-top: 170px;
  margin-top: -140px;
}
@media screen and (max-width: 575px) {
  .footerMain .footer {
    margin-top: -100px;
  }
}
.footerMain .footer .footerLogo {
  margin-left: -10px;
}
.footerMain .footer p {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.footerMain .footer h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */
  color: #ffffff;
}
.footerMain .footer h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 156.69%;
  /* or 28px */
  text-transform: uppercase;
  color: #d0d0d0;
  margin-bottom: 12px;
}
.footerMain .footer ul {
  padding-left: 0px;
}
.footerMain .footer ul li {
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */
  color: #ffffff;
  cursor: pointer;
}

.virtualTours {
  background: #000000;
}
.virtualTours h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 54px;
  /* identical to box height */
  margin-top: 30px;
  margin-bottom: 0px;
  color: #ffffff;
}
.virtualTours button {
  background: #cc2939;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #cc2939;
  padding: 5px 10px;
  letter-spacing: -0.2px;
  /* icon/white */
  color: #ffffff;
}
.virtualTours .viewAllBlog {
  background: #cc2939;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  border: 1px solid #cc2939;
  /* identical to box height */
  min-width: 200px;
  color: #fff;
}
.virtualTours .textToShow {
  height: 400px;
  overflow: hidden;
}
.virtualTours .textToShow p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  margin-bottom: 30px;
  /* or 196% */
  text-align: justify;
  letter-spacing: 0.01em;
  /* white */
  color: #ffffff;
}
.virtualTours .textToShow.opened {
  height: auto;
}
.virtualTours .readMoreBtn {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
  height: 150px;
}
.virtualTours .readMoreBtn .blackDiv {
  background: linear-gradient(180deg, rgba(4, 5, 6, 0) 0%, #040506 39.06%);
  filter: blur(30px);
  height: 100%;
  position: absolute;
  width: 100%;
}
.virtualTours .readMoreBtn button {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.15px;
  background: #ffffff;
  border-radius: 8px;
  color: #cc2939;
  position: relative;
  border: 1px solid white;
  padding: 7px 20px;
  margin-top: 30px;
}
.virtualTours .readMoreBtn.opened {
  margin-top: 0px;
  height: 50px;
}

.propertyViewing .carousel-control-next,
.propertyViewing .carousel-control-prev {
  opacity: 1;
}
.propertyViewing .carousel-control-next-icon,
.propertyViewing .carousel-control-prev-icon {
  background-color: #cc2939;
  border-radius: 50%;
  background-size: 16px;
}
.propertyViewing .carousel-caption p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height */
  color: #ffffff;
}
.propertyViewing .carousel-indicators {
  display: none;
}
.propertyViewing .propertyTags {
  background-color: #000000;
  padding: 40px 0px;
}
.propertyViewing .propertyTags .tagBox {
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 10px;
}
.propertyViewing .propertyTags .tagBox h1 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #ffffff;
}
.propertyViewing .propertyTags .tagBox p {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0px;
  /* identical to box height */
  letter-spacing: 0.2px;
  cursor: pointer;
  /* white */
  color: #ffffff;
}
.propertyViewing .propertyTags .tagBox span {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #ffffff;
  background: #cc2939;
  border-radius: 6px;
  padding: 5px;
}

.interestingExperience {
  z-index: 9;
  position: relative;
  background: white;
}
.interestingExperience h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 54px;
  text-transform: capitalize;
  color: #343434;
}
.interestingExperience button {
  width: 280px;
  height: 68px;
  background: #cc2939;
  border-radius: 8px;
  border: 1px solid #cc2939;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #ffffff;
  padding: 0px 90px;
}

.ratingMain {
  padding: 30px 0px;
}
.ratingMain h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 54px;
  text-transform: capitalize;
  color: #cc2939;
  text-align: center;
}
.ratingMain h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  border-bottom: 1px solid #cc2939;
  /* identical to box height, or 75% */
  letter-spacing: 1px;
  padding-bottom: 5px;
}
.ratingMain button {
  width: 100px;
  height: 35px;
}
.ratingMain .textToShow {
  height: 538px;
  overflow: hidden;
  padding-left: 20px;
}
.ratingMain .textToShow .list {
  padding: 20px 0px;
  border-bottom: 0.5px solid rgba(116, 116, 116, 0.4431372549);
}
.ratingMain .textToShow .list img {
  width: 80px;
  height: 80px;
}
.ratingMain .textToShow .list h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 39px;
  /* identical to box height */
  margin-bottom: 0px;
}
.ratingMain .textToShow .list p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 33px;
  margin-bottom: 0px;
  color: #000000;
}
.ratingMain .textToShow .list .reviewrRating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .ratingMain .textToShow .list .reviewrRating .rating {
    justify-content: end;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.ratingMain .textToShow .list h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
  /* identical to box height */
  text-align: right;
  /* grey */
  color: #747474;
}
.ratingMain .textToShow .list .rating.checked {
  color: #ffbc0b;
}
.ratingMain .textToShow.opened {
  height: auto;
}
.ratingMain .readMoreBtn {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -82px;
  height: 200px;
}
.ratingMain .readMoreBtn .blackDiv {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 39.06%
  );
  filter: blur(30px);
  height: 100%;
  position: absolute;
  width: 100%;
}
.ratingMain .readMoreBtn button {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.15px;
  background: #cc2939;
  border-radius: 8px;
  color: white;
  position: relative;
  border: 1px solid #cc2939;
  padding: 7px 20px;
}
.ratingMain .loadMore button {
  width: 200px;
  margin-top: 50px;
}

.locationAdddress {
  background-color: #000000;
  padding: 50px 0px 100px 0px;
  position: relative;
  z-index: 9;
}
.locationAdddress h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  padding-bottom: 20px;
  /* identical to box height, or 75% */
  letter-spacing: 1px;
  border-bottom: 0.5px solid rgba(204, 41, 57, 0.5803921569);
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;
}
.locationAdddress h6 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32 px;
  letter-spacing: 0.4px;
  margin-bottom: 0px;
  /* white */
  color: #ffffff;
}
.locationAdddress .spacer {
  width: 1px;
  background: rgba(204, 41, 57, 0.5803921569);
  height: 60px;
}

.aboutUsMain .banner {
  background-image: url(../../../public/imgs/aboutus-bg.png);
  background-size: cover;
  min-height: 550px;
}
.aboutUsMain .banner .h-500px {
  height: 550px;
}
.aboutUsMain .banner .h-500px h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 76px;
  line-height: 144px;
  /* identical to box height */
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .aboutUsMain .banner .h-500px h1 {
    font-size: 45px;
  }
}
.aboutUsMain .banner .h-500px p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.aboutUsMain .ourMission {
  background: white !important;
  padding: 50px 0px;
}
.aboutUsMain .ourMission h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 72px;
  /* identical to box height */
  /* white */
  color: #ffffff;
}
.aboutUsMain .ourMission p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  /* white */
  color: #000000;
}

.contactUsMain .contactUs {
  background-image: url(../../../public/imgs/contact-bg.png);
  background-size: cover;
}
.contactUsMain .contactUs h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 76px;
  line-height: 100px;
  /* identical to box height */
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .contactUsMain .contactUs h1 {
    font-size: 45px;
  }
}
.contactUsMain .contactUs p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.contactUsMain .contactUs .contactCard {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}
.contactUsMain .contactUs .contactCard label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  text-align: left !important;
  /* identical to box height, or 181% */
  color: #000000;
}
.contactUsMain .contactUs .contactCard textarea {
  height: 119px;
}
.contactUsMain .contactUs .contactCard .input-group {
  background: #eeeeee;
  border-radius: 8px !important;
  border: none !important;
  width: 100%;
  height: 100%;
  align-items: start;
}
.contactUsMain .contactUs .contactCard .input-group textarea {
  border: none !important;
  background: transparent;
}
.contactUsMain .contactUs .contactCard .input-group span {
  padding-top: 10px;
}
.contactUsMain .contactUs .contactCard .addressInfo {
  background: #eeeeee;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
}
.contactUsMain .contactUs .contactCard .addressInfo ul {
  padding-left: 15px;
  margin-bottom: 0px;
}
.contactUsMain .contactUs .contactCard .addressInfo ul li {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
  color: #343434;
}
.contactUsMain .contactUs .haveQuestion h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 84px;
  margin-top: 40px;
  /* identical to box height */
  margin-bottom: 20px;
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .contactUsMain .contactUs .haveQuestion h1 {
    font-size: 27px;
    line-height: 50px;
  }
}
.contactUsMain .contactUs .haveQuestion input.form-control {
  background: #ffffff !important;
  border-radius: 8px !important;
  height: 50px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  border: none !important;
  /* identical to box height, or 181% */
  color: #000000 !important;
}
.contactUsMain .contactUs .haveQuestion input.form-control::-moz-placeholder {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  /* identical to box height, or 181% */
  color: #000000 !important;
}
.contactUsMain .contactUs .haveQuestion input.form-control::placeholder {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  /* identical to box height, or 181% */
  color: #000000 !important;
}
.contactUsMain .contactUs .haveQuestion textarea.form-control {
  background: #ffffff !important;
  border-radius: 8px !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  border: none !important;
  /* identical to box height, or 181% */
  color: #000000 !important;
}
.contactUsMain
  .contactUs
  .haveQuestion
  textarea.form-control::-moz-placeholder {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  /* identical to box height, or 181% */
  color: #000000 !important;
}
.contactUsMain .contactUs .haveQuestion textarea.form-control::placeholder {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  /* identical to box height, or 181% */
  color: #000000 !important;
}

.blogMain .blogBanner {
  background-image: url(../../../public/imgs/blog-bg.png);
  background-size: cover;
  position: relative;
}
.blogMain .blogBanner .logo {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.blogMain .blogBanner h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 76px;
  line-height: 100px;
  /* identical to box height */
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .blogMain .blogBanner h1 {
    font-size: 45px;
  }
}
.blogMain .blogBanner .veticallyCentered {
  padding: 10% 0px;
}
@media screen and (max-width: 767px) {
  .blogMain .blogBanner .veticallyCentered {
    padding: 15% 0px;
  }
}
@media screen and (max-width: 575px) {
  .blogMain .blogBanner .veticallyCentered {
    padding: 100px 0px;
  }
}
.blogMain .blogBanner .input-group {
  padding: 0px;
  background-color: white !important;
  border-radius: 8px !important;
  height: 50px;
}
.blogMain .blogBanner .input-group .input-group-text {
  padding: 0px !important;
  margin-right: -1px;
  margin-top: -1px;
  margin-bottom: -1px;
}
.blogMain .blogBanner .input-group .input-group-text button {
  height: 50px !important;
  width: 120px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
}
.blogMain .blogBanner .input-group input {
  padding-left: 20px;
}
.blogMain .blogBanner .blackTags {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}
.blogMain .blogBanner .blackTags p {
  background: #2b2e35;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  color: #ffffff;
  padding: 10px 15px;
}

.featuredArticles {
  padding: 50px 0px !important;
}
.featuredArticles h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 42px;
  margin-bottom: 0px;
  /* identical to box height */
  color: #2b2e35;
}
.featuredArticles p.pera {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  /* identical to box height */
  color: #2b2e35;
}
.featuredArticles .featuredArticlesList {
  background-image: none;
}
@media screen and (max-width: 767px) {
  .featuredArticles .featuredArticlesList .px-50px {
    padding: 0px !important;
  }
}
.featuredArticles .featuredArticlesList .blogCard {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}
.featuredArticles .featuredArticlesList .blogCard h2 {
  max-width: 90%;
}
@media screen and (max-width: 575px) {
  .featuredArticles .featuredArticlesList .blogCard h2 {
    max-width: 100%;
  }
}
.featuredArticles .featuredArticlesList .blogCard p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #747474;
  margin-bottom: 0px;
}
.featuredArticles .featuredArticlesList .blogCard .youtubeLogo {
  width: 100px;
}
.featuredArticles .featuredArticlesList .blogCard button {
  width: 110px;
}
.featuredArticles .featuredArticlesList .blogCard .ytIcon {
  width: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.featuredArticles .featuredArticlesList .allTours {
  text-align: left;
  margin-bottom: 40px;
}
.featuredArticles .pagination {
  display: inline-block;
}
.featuredArticles .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  border: 1px solid #cc2939;
  border-right: none;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #cc2939;
}
.featuredArticles .pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.featuredArticles .pagination a:last-child {
  border-right: 1px solid #cc2939;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.featuredArticles .pagination a.active {
  background-color: #cc2939;
  color: white;
  border: 1px solid #cc2939;
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.featuredArticles .pagination a:hover:not(.active) {
  background-color: #cc2939;
  color: white;
}
.featuredArticles .reviewMain {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #cc2939;
  margin: 30px auto 0px;
}
.featuredArticles .reviewMain .cardSection {
  border: none;
  padding: 0px !important;
}
.featuredArticles .reviewMain .cardSection .seperator {
  height: 1px;
  background: rgba(156, 156, 156, 0.5333333333);
}
.featuredArticles .reviewMain .cardSection .back {
  border: 1px solid #cc2939;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  background: transparent;
  /* identical to box height, or 219% */
  color: #cc2939;
  width: 88px;
  height: 40px;
}
.featuredArticles .reviewMain .cardSection .close {
  background: #cc2939;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  border: 1px solid #cc2939;
  /* identical to box height, or 219% */
  width: 88px;
  height: 40px;
  color: #ffffff;
}
.featuredArticles .reviewMain .cardSection .d-flex.justify-content-between {
  flex-direction: row;
}
.featuredArticles .reviewMain .detailAboutBlog {
  display: flow-root;
}
.featuredArticles .reviewMain .detailAboutBlog .detailBlogImg {
  float: left;
  height: 462px !important;
  width: 65% !important;
  margin-right: 20px;
  margin-bottom: 20px;
}
.featuredArticles .reviewMain .detailAboutBlog .detailBlogImg img {
  -o-object-fit: contain;
  object-fit: contain;
}
@media screen and (max-width: 1024px) {
  .featuredArticles .reviewMain .detailAboutBlog .detailBlogImg {
    width: 100% !important;
  }
}
.featuredArticles .reviewMain .detailAboutBlog h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height, or 161% */
  color: #000000;
}
.featuredArticles .reviewMain .detailAboutBlog img.fountain {
  border-radius: 8px;
}
.featuredArticles .reviewMain .detailAboutBlog p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: left !important;
  text-align: center;
  letter-spacing: 0.12em;
  margin-bottom: 0px;
  color: #747474;
}
.featuredArticles .reviewMain .detailAboutBlog p.description {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
  /* or 139% */
  text-align: justify;
  color: #747474;
}
.featuredArticles .reviewMain .detailAboutBlog .divi {
  background: #747474;
  width: 2px;
  height: 15px;
}
.featuredArticles .reviewMain .detailAboutBlog .profileName img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.featuredArticles .reviewMain .detailAboutBlog .profileName h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  /* or 231% */
  margin-bottom: 0px;
  color: #747474;
}

.form-select:disabled {
  background: transparent !important;
}

.dashboardMain .dashCard {
  padding: 30px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
}
.dashboardMain .dashCard i {
  font-size: 50px;
  color: #cc2939;
  margin-bottom: 10px;
}
.dashboardMain .dashCard h1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  color: #343434;
  margin-bottom: 0px;
}
.dashboardMain .dashCard h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #343434;
  margin-bottom: 0px;
}
.dashboardMain .dashCard p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 19px;
  color: #cc2939;
  margin-bottom: 0px;
  margin-top: 10px;
}

.outTitle {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}

.questionareDetailForm label {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}
.questionareDetailForm input[type="text"] {
  border: none !important;
  border-bottom: 1px solid #c2c2c2 !important;
  border-radius: 0px !important;
}
.questionareDetailForm h3 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}
.questionareDetailForm hr {
  margin: 0px -20px;
}
.questionareDetailForm .form-check .form-check-input {
  border-color: #cc2939;
}
.questionareDetailForm .form-check label {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}
.questionareDetailForm .form-check-input:checked {
  background: #cc2939 !important;
  border-color: #cc2939;
}
.questionareDetailForm .questions input {
  border: 1px solid rgba(0, 0, 0, 0.5843137255) !important;
}

.rdt_TableHeadRow {
  justify-content: space-between !important;
}

.rdt_TableRow input {
  border-color: #000000 !important;
  width: 1.2rem !important;
  height: 1.2rem !important;
  margin-top: 13px;
}
.rdt_TableRow input:focus {
  box-shadow: none !important;
}
.rdt_TableRow input:checked {
  width: 1.2rem !important;
  height: 1.2rem !important;
  background: #03f690 !important;
  border-color: #000000 !important;
  padding: 5px;
  margin-top: 13px;
}
.rdt_TableRow input:checked:focus {
  box-shadow: none !important;
}
.rdt_TableRow input[type="checkbox"] {
  height: initial !important;
}

.radioStatus .radioBtns {
  text-align: center;
  width: 120px !important;
}
.radioStatus .radioBtns p {
  display: inline-block;
}

.inActive input {
  margin-top: 0px !important;
}

@media screen and (max-width: 992px) {
  .openSidebar.mainOverlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.5411764706);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
}

.mapBox .openlayers-map {
  height: 500px;
}

.orderProductPlan .row.dividerRow {
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 13px;
}
.orderProductPlan h1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */
  text-transform: capitalize;
  /* Dark Grey */
  color: #343434;
}
.orderProductPlan h3 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  margin-bottom: 20px;
  /* Dark Grey */
  color: #343434;
}
.orderProductPlan p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* Dark Grey */
  color: #343434;
}
.orderProductPlan span {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.2px;
  /* Dark Grey */
  color: #343434;
  background: #ffcfd4;
  border-radius: 60px;
  padding: 5px 10px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}
.orderProductPlan .priceMain p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  margin-bottom: 0px;
  /* Dark Grey */
  color: #343434;
}
.orderProductPlan .priceMain button {
  background: #cc2939;
  border-radius: 19px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  padding: 5px 30px;
  padding-bottom: 7px;
  /* white */
  color: #ffffff;
}
.orderProductPlan .getQuoted {
  background: #fffbfb;
}
.orderProductPlan .getQuoted span {
  background: #fff4bd;
}
.orderProductPlan .getQuoted .priceMain button {
  background: #fede38;
  color: #343434;
  text-transform: capitalize;
  padding-bottom: 7px;
}

.orderProductPlanModal .avatar-preview .form-check-input {
  border: 1.5px solid #d9d9d9 !important;
}
.orderProductPlanModal .loginBtn.type {
  border-radius: 20px;
  height: 32px;
  width: 63px;
}

.mt-25px {
  margin-top: 25px !important;
}

input[type="file"] {
  margin-top: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.poppins {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}

.w-230 {
  width: 230px;
}

.innerTitle {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  margin-bottom: 0px;
  letter-spacing: 0.4px;
  /* grayscale / black */
  color: #252733;
}

.userManagement span i.fa-angle-down {
  display: block;
}
.userManagement span div i.fa-angle-down {
  display: none;
}
.userManagement span.active i.fa-angle-down {
  display: none;
}
.userManagement span.active div {
  display: flex;
  align-items: center;
  width: 100%;
}
.userManagement span.active div i.fa-angle-down {
  display: block;
  position: absolute;
  right: 44px;
}

.btn:disabled {
  background-color: lightgray !important;
  border: lightgray !important;
}

.upgradeAccount h5 {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 35px;
}
.upgradeAccount .card {
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin: 10px 0;
}
.upgradeAccount .card .plan {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}
.upgradeAccount .card .plan .name-price h4 {
  font-size: 26px;
  line-height: 30px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  font-weight: 700;
}
.upgradeAccount .card .plan .name-price h3 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  display: flex;
  align-items: center;
  color: #cc2939;
  font-family: "Roboto", sans-serif;
}
.upgradeAccount .card .plan span {
  background: #fede38;
  border-radius: 60px;
  color: #000;
  font-weight: 700;
  padding: 4px 10px;
  font-size: 12px;
}
.upgradeAccount .card .plan-detail {
  padding: 20px;
  border-top: 0.5px solid #d9d9d9;
  border-bottom: 0.5px solid #d9d9d9;
}
.upgradeAccount .card .plan-detail ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.upgradeAccount .card .plan-detail ul li {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #343434;
  font-family: "Roboto", sans-serif;
}
.upgradeAccount .card .plan-detail ul li:last-child {
  margin-bottom: 0;
}
.upgradeAccount .card .plan-detail ul li img {
  width: 20px;
  margin-right: 10px;
}
.upgradeAccount .card .subscribe {
  padding: 25px 20px;
  text-align: center;
}
.upgradeAccount .card .subscribe .btn {
  background: #cc2939;
  border-radius: 60px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  padding: 8px 28px;
}

.staffMainDiv {
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding: 20px;
}
@media screen and (max-width: 575px) {
  .staffMainDiv .d-flex.justify-content-between {
    flex-direction: column;
    gap: 10px;
  }
}
.staffMainDiv a .loginBtn {
  width: auto;
}
.staffMainDiv .input-group {
  padding: 0px;
  border-radius: 30px !important;
}
.staffMainDiv .input-group img {
  margin-top: 0px !important;
}
.staffMainDiv ul {
  padding-left: 0px;
}
.staffMainDiv ul li {
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px;
  list-style: none;
  margin-bottom: 15px;
  position: relative;
}
.staffMainDiv ul li a {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 40px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.2px;
  text-decoration: none;
  /* grey */
  color: #747474;
}
.staffMainDiv .accordion {
  margin-bottom: 10px;
}
.staffMainDiv .accordion:last-child {
  margin-bottom: 0;
}
.staffMainDiv .accordion .accordion-item {
  border: 1px solid #c9c9c9;
  position: relative;
}
.staffMainDiv .accordion .accordion-item .button-wrapper {
  position: absolute;
  right: 15px;
  top: 20px;
  display: flex;
  gap: 10px;
  z-index: 9;
  cursor: pointer;
}
.staffMainDiv .accordion .accordion-item .button-wrapper img {
  width: 18px;
}
.staffMainDiv .accordion .accordion-item .accordion-button {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  justify-content: left;
  padding: 1rem;
}
.staffMainDiv .accordion .accordion-item .accordion-button::after {
  display: none;
}
.staffMainDiv .accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.staffMainDiv .accordion .accordion-item .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none;
  padding: 1rem;
  justify-content: left;
}
.staffMainDiv .accordion .accordion-body {
  padding: 1rem;
}

.commission_rangePicker input {
  border: 1px solid #747474 !important;
  border-radius: 100px;
  padding: 5px 10px;
  height: 45px;
  margin-right: 15px;
}

.answerTypeFont .form-check label {
  font-size: 16px;
}

.quill .ql-container {
  height: 300px;
} /*# sourceMappingURL=style.css.map */
.tourDetailModal {
  width: 320px;
  position: absolute;
  top: 140px;
  left: auto;
  display: block;
  padding-left: 20px;
}
@media screen and (max-width: 600px) {
  .tourDetailModal {
    width: 100%;
    position: absolute;
    top: 140px;
    left: auto;
    display: block;
    padding-left: 0;
  }
}
.pin_back {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pinInner {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: relative;
  margin-top: -10px;
  object-fit: cover;
}

.cardSection .ql-tooltip.ql-editing {
  left: 0px !important;
}

.review-comments {
  height: 450px !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 100px;
    margin: 10px auto;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 100px;
  }
}
